import React, { useState, useCallback, useMemo } from "react"
import { map, keyBy } from "lodash"
import styled from "styled-components"

import { Image, TextGroup } from "components/common"

const _TestimonalSelector = ({ testimonials }) => {
  const [testimonialID, selectTestimonial] = useState(testimonials[0].id)

  const testimonialById = useMemo(
    () => keyBy(testimonials, "id"),
    [testimonials]
  )

  const renderIcons = useCallback(
    () =>
      map(testimonials, ({ id, icon }) => (
        <li
          key={id}
          className={`testimonialIcon ${testimonialID === id ? "active" : ""}`}
        >
          <button className="selector" onClick={() => selectTestimonial(id)}>
            <Image className="icon" image={icon} />
          </button>
        </li>
      )),
    [testimonials, testimonialID]
  )

  return (
    <TestimonialWrap>
      <TestimonialIcons>{renderIcons()}</TestimonialIcons>
      <Testimonial>
        <TextGroup
          align="center"
          titleAs="p"
          subtitleAs="h3"
          title={testimonialById[testimonialID].title}
          subtitle={testimonialById[testimonialID].subtitle}
        />
      </Testimonial>
    </TestimonialWrap>
  )
}

const TestimonialWrap = styled.div`
  --tg-title-color: var(--subtitle-font-color);
  --tg-subtitle-color: #5f5f5f;
`

const TestimonialIcons = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 60px));
  justify-content: center;
  grid-gap: var(--sp-16);

  .testimonialIcon {
    opacity: 0.5;
    cursor: pointer;
    transition: 0.1s all ease-in-out;
    transition-property: opacity, transform;
    will-change: opacity, transform;

    &:hover {
      transform: scale(1.1);
      opacity: 1;
    }

    &.active {
      transform: scale(1.1);
      opacity: 1;
    }

    .selector {
      cursor: pointer;
    }

    .icon {
      height: 50px;
      width: 50px;
      /* width: 100%;
      height: 100%; */
    }
  }
`

const Testimonial = styled.div`
  margin-top: var(--sp-32);
  text-align: center;
  --tg-max-width: 756px;

  h5 {
    strong {
      font-weight: 700;
    }
  }
`
export default _TestimonalSelector
