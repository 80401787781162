import React from "react"
import styled from "styled-components"
import { VerticalSection } from "components/section_templates"
import { TestimonialSelector } from "components/sections/about"

const _AboutTestimonial = ({ hat, title, valueProps }) => {
  return (
    <AboutTestimonial>
      <VerticalSection
        hat={hat}
        title={title}
        id="about_testimonial"
        custom={() => <TestimonialSelector testimonials={valueProps} />}
      />
    </AboutTestimonial>
  )
}

const AboutTestimonial = styled.div`
  --tg-max-width: 854px;
  --section-background-alt: none;
`

export default _AboutTestimonial
