import React, { useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Section, TextGroup, Image } from "components/common"

import { device } from "utils"
import classNames from "classnames"

/**
 * @description Horizational Template Section
 * 
* @typedef {{
 *  right: boolean
 *  align: 'left' | 'right' 
 *  full: boolean=false
 *  hat: string
 *  title: string
 *  subtitle: string
 *  image: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  mobileImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  backgroundImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  cta: React.FunctionComponent
 *  custom: React.FunctionComponent
 *  grid: React.FunctionComponent
 *  className: String
 *  tgOrder: "1" | "2" | "3" | "4" 
 *  ctaOrder: "1" | "2" | "3" | "4" 
 *  gridOrder: "1" | "2" | "3" | "4" 
 *  customOrder: "1" | "2" | "3" | "4" 
 *  mobileTgOrder: "1" | "2" | "3" | "4" | "5"
 * mobileCtaOrder: "1" | "2" | "3" | "4" | "5"
 * mobileImageOrder: "1" | "2" | "3" | "4" | "5"
 * mobileGridOrder: "1" | "2" | "3" | "4" | "5"
 * mobileCustomOrder: "1" | "2" | "3" | "4" | "5"
 * }}
 * 
 * Props
 * @type React.FunctionComponent<Props>
 
 */

const HorizontalSection = ({
  first,
  right,
  align,
  full,
  hat,
  title,
  subtitle,
  ctas,
  image,
  mobileImage,
  backgroundImage,
  mobileBackgroundImage,
  grid,
  hatAs,
  titleAs,
  subtitleAs,
  className,
  custom,
  tgOrder,
  ctaOrder,
  gridOrder,
  customOrder,
  mobileTgOrder,
  mobileCtaOrder,
  mobileImageOrder,
  mobileGridOrder,
  mobileCustomOrder,
}) => {
  const renderGrid = useCallback(() => grid(), [grid])
  const renderCtas = useCallback(() => ctas(), [ctas])
  const renderCustom = useCallback(() => custom(), [custom])
  return (
    <StyledHorizontalSection className={className}>
      <Section
        image={backgroundImage}
        mobileImage={mobileBackgroundImage}
        full={full}
        first={first}
      >
        <HalfSideContainer
          right={right}
          className={
            right ? "right half-side-container" : "half-side-container"
          }
        >
          <TextSection right={right} className="horizontal_textSection">
            <TGWrap
              tgOrder={tgOrder}
              mobileTgOrder={mobileTgOrder}
              className="tgWrap"
            >
              <TextGroup
                hat={hat}
                title={title}
                subtitle={subtitle}
                align={align}
                hatAs={hatAs}
                titleAs={titleAs}
                subtitleAs={subtitleAs}
              />
            </TGWrap>
            {ctas && (
              <CTAWrap
                className="ctaWrap"
                align={align}
                ctaOrder={ctaOrder}
                mobileCtaOrder={mobileCtaOrder}
              >
                {renderCtas()}
              </CTAWrap>
            )}
            {grid && (
              <GridWrap
                className="gridWrap"
                gridOrder={gridOrder}
                mobileGridOrder={mobileGridOrder}
              >
                {renderGrid()}
              </GridWrap>
            )}
            {custom && (
              <CustomWrap
                className="customWrap"
                customOrder={customOrder}
                mobileCustomOrder={mobileCustomOrder}
              >
                {renderCustom()}
              </CustomWrap>
            )}
          </TextSection>
          {image && (
            <ImageSection
              height={image.height}
              width={image.width}
              right={right}
              mobileImageOrder={mobileImageOrder}
              className={classNames("imageSection", { full })}
            >
              <Image
                image={image}
                mobileImage={mobileImage}
                fadeIn={image.fadeIn}
                loading={image.loading}
              />
            </ImageSection>
          )}
        </HalfSideContainer>
      </Section>
    </StyledHorizontalSection>
  )
}

const ALIGN_LIB = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
}

const StyledHorizontalSection = styled.div``

const HalfSideContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-gap: var(--sp-32);

  @media ${device.laptop} {
    grid-template-columns: repeat(auto-fill, minMax(480px, auto));
    grid-gap: var(--sp-64);
  }
`

const TextSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  order: 2;
  @media ${device.laptop} {
    order: ${({ right }) => (right ? 2 : 1)};
  }
`

const TGWrap = styled.div`
  order: ${({ mobileTgOrder }) => mobileTgOrder};

  margin-bottom: ${({ mobileTgOrder }) =>
    mobileTgOrder === "1" ? "var(--sp-32)" : 0};

  @media ${device.laptop} {
    margin-bottom: 0;
    order: ${({ tgOrder }) => tgOrder};
  }
`

const CTAWrap = styled.div`
  margin-top: var(--sp-32);
  align-self: ${({ align }) => ALIGN_LIB[align]};

  order: ${({ mobileCtaOrder }) => mobileCtaOrder};
  @media ${device.laptop} {
    order: ${({ ctaOrder }) => ctaOrder};
  }
`

const GridWrap = styled.div`
  margin-top: var(--sp-32);
  order: ${({ mobileGridOrder }) => mobileGridOrder};
  @media ${device.laptop} {
    order: ${({ gridOrder }) => gridOrder};
    margin-top: var(--sp-64);
  }
`

const CustomWrap = styled.div`
  margin-top: var(--sp-32);

  order: ${({ mobileCustomOrder }) => mobileCustomOrder};
  @media ${device.laptop} {
    order: ${({ customOrder }) => customOrder};
  }
`

const ImageSection = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  order: ${({ mobileImageOrder }) => mobileImageOrder};

  @media ${device.laptop} {
    order: ${({ right }) => (right ? 1 : 2)};
  }

  &.full {
    position: relative;
    left: 50%;
    margin: 0 -50vw;
    max-width: 100vw;
    right: 50%;
    width: 100vw;

    @media ${device.laptop} {
      left: initial;
      right: initial;
      width: initial;
      max-width: initial;
      margin-right: ${({ right }) =>
        right ? "initial" : "calc(-100vw / 2 + var(--section-max-width) / 2)"};
      margin-left: ${({ right }) =>
        right ? "calc(-100vw / 2 + var(--section-max-width) / 2)" : "initial"};
    }
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  /* .image {
    width: 100%;
  } */
`

HorizontalSection.defaultProps = {
  first: false,
  right: false,
  align: "left",
  full: false,
  hat: "",
  title: "",
  subtitle: "",
  height: null,
  image: null,
  mobileImage: null,
  cta: null,
  grid: null,
  hatAs: "h5",
  titleAs: "h2",
  subtitleAs: "p",
  tgOrder: "1",
  ctaOrder: "2",
  gridOrder: "3",
  customOrder: "3",

  mobileImageOrder: "1",
  mobileTgOrder: "2",
  mobileGridOrder: "3",
  mobileCustomOrder: "3",
  mobileCtaOrder: "4",
}

// HorizontalSection.propTypes = {
//   right: PropTypes.bool,
//   full: PropTypes.bool,
//   align: PropTypes.oneOf(["left", "right"]),
//   hat: PropTypes.oneOfType([
//     PropTypes.shape({
//       json: PropTypes.object.isRequired,
//     }),
//     PropTypes.string,
//   ]),
//   subtitle: PropTypes.oneOfType([
//     PropTypes.shape({
//       json: PropTypes.object.isRequired,
//     }),
//     PropTypes.string,
//   ]),
//   title: PropTypes.oneOfType([
//     PropTypes.shape({
//       json: PropTypes.object.isRequired,
//     }),
//     PropTypes.string,
//   ]),
//   height: PropTypes.string,
//   image: PropTypes.shape({
//     width: PropTypes.string,
//     height: PropTypes.string,
//     src: PropTypes.string,
//     alt: PropTypes.string,
//     fixed: PropTypes.shape({
//       width: PropTypes.number.isRequired,
//       height: PropTypes.number.isRequired,
//     }),
//     file: PropTypes.shape({
//       url: PropTypes.string.isRequired,
//       fileName: PropTypes.string,
//     }),
//     fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
//   }),
//   mobileImage: PropTypes.shape({
//     width: PropTypes.string,
//     height: PropTypes.string,
//     src: PropTypes.string,
//     alt: PropTypes.string,
//     fixed: PropTypes.shape({
//       width: PropTypes.number.isRequired,
//       height: PropTypes.number.isRequired,
//     }),
//     file: PropTypes.shape({
//       url: PropTypes.string.isRequired,
//       fileName: PropTypes.string,
//     }),
//     fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
//   }),
//   backgroundImage: PropTypes.shape({
//     width: PropTypes.string,
//     height: PropTypes.string,
//     src: PropTypes.string,
//     alt: PropTypes.string,
//     fixed: PropTypes.shape({
//       width: PropTypes.number.isRequired,
//       height: PropTypes.number.isRequired,
//     }),
//     file: PropTypes.shape({
//       url: PropTypes.string.isRequired,
//       fileName: PropTypes.string,
//     }),
//     fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
//   }),
//   cta: PropTypes.func,
//   custom: PropTypes.func,
//   grid: PropTypes.func,
//   tgOrder: PropTypes.oneOf(["1", "2", "3", "4"]).isRequired,
//   ctaOrder: PropTypes.oneOf(["1", "2", "3", "4"]).isRequired,
//   gridOrder: PropTypes.oneOf(["1", "2", "3", "4"]).isRequired,
//   customOrder: PropTypes.oneOf(["1", "2", "3", "4"]).isRequired,
//   mobileTgOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   mobileCtaOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   mobileImageOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   mobileGridOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   mobileCustomOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
// }

export default HorizontalSection
