import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/section_templates"
import { Grid, Image } from "components/common"

const _AboutIllistrators = ({ hat, title, subtitle, gallery }) => {
  const grid = Array(8).fill({ fluid: gallery[0].fluid })

  return (
    <AboutIllistrators>
      <VerticalSection
        hat={hat}
        title={title}
        subtitle={subtitle}
        grid={() => (
          <Grid
            gap={32}
            col={4}
            width="176px"
            align="center"
            component={(image) => (
              <GridCard>
                <Image image={{ className: "svg", ...image }} />
              </GridCard>
            )}
            list={grid}
          />
        )}
      />
    </AboutIllistrators>
  )
}

const AboutIllistrators = styled.div`
  --tg-max-width: 650px;
  --section-background-color: var(--bg-background-color);
`

const GridCard = styled.li`
  box-shadow: var(--card-shadow);
  border-radius: 50%;
`
export default _AboutIllistrators
