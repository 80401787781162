import React from "react"
import styled from "styled-components"

import { BottomCTA } from "components/common"

const _AboutBottomCTA = (props) => {
  return (
    <AboutBottomCTA>
      <BottomCTA {...props} />
    </AboutBottomCTA>
  )
}

const AboutBottomCTA = styled.div``

export default _AboutBottomCTA
