import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import { VerticalSection } from "components/section_templates"
import { Grid, Image } from "components/common"
import { ValuePropFull } from "components/valueProps"
import { device } from "src/utils"

const _AboutTeam = ({ hat, title, subtitle, valueProps }) => {
  return (
    <AboutTeam>
      <VerticalSection
        hat={hat}
        title={title}
        subtitle={subtitle}
        grid={() => (
          <Grid
            align="center"
            col={4}
            list={valueProps}
            className="about-grid"
            component={(props) => (
              <VPWrap>
                {/* {props.altIcon && (
                  <Image className="altIcon" image={props.altIcon} />
                )} */}

                <ValuePropFull
                  className="aboutVP"
                  align="center"
                  isCard
                  custom={() => <AboutLinks links={props.components} />}
                  {...props}
                />
              </VPWrap>
            )}
          />
        )}
      />
    </AboutTeam>
  )
}

const AboutTeam = styled.div`
  --tg-max-width: 700px;
  --section-max-width: 1440px;

  .gridWrap {
    .about-grid {
      grid-template-columns: 1fr;

      @media ${device.tablet} {
        grid-template-columns: repeat(2, minmax(min-content, 300px));
        align-items: stretch;
      }

      @media ${device.laptop} {
        grid-template-columns: repeat(3, minmax(min-content, 300px));
        /* grid-template-columns: repeat(auto-fit, minmax(min-content, 300px)); */
      }
    }
  }
`

const VPWrap = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;

  --card-padding: var(--sp-32) var(--sp-24);

  /* .altIcon {
    display: block;
    max-height: 72px;
    height: 100%;
    margin-bottom: var(--sp-16);
  } */

  .aboutVP {
    display: inline-flex;
    flex-direction: column;

    .image,
    .gatsby-image-wrapper {
      @media ${device.laptop} {
        height: 100%;
        max-height: 335px;

        img {
          object-fit: cover !important;
        }
      }
    }
  }
`

const AboutLinks = ({ links }) => {
  return (
    <StyledAboutLinks>
      {map(links, ({ id, image, ctas }) => {
        const href = ctas[0].url

        return (
          <AboutLink key={id}>
            <a href={href} target="_blank" rel="noreferrer">
              <Image image={image} />
            </a>
          </AboutLink>
        )
      })}
    </StyledAboutLinks>
  )
}

const StyledAboutLinks = styled.ul`
  margin-top: var(--sp-16);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 24px));

  grid-gap: var(--sp-8);
  justify-content: center;
  width: 100%;
`
const AboutLink = styled.li`
  a {
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    transition: filter 0.2s ease-in-out;
    will-change: filter;
    &:hover {
      filter: opacity(0.8);
    }
  }
`

export default _AboutTeam
