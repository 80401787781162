import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav, BottomCTA } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import { AboutTeam, AboutTestimonial } from "src/components/sections/about"

const AboutPage = ({ data: { about } }) => {
  const sections = useContentfulSection(about.sections)
  const meta = useMeta(about)

  const {
    about_team,

    about_testimonial,
    bottom_cta,
  } = sections

  return (
    <Layout>
      <Seo {...meta} />
      <Nav />
      <Wrap>
        <AboutTeam {...about_team} />
        <AboutTestimonial {...about_testimonial} />
        <BottomCTA {...bottom_cta} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  padding-top: var(--sp-80);
`

export const query = graphql`
  {
    about: contentfulPage(pageId: { eq: "about" }) {
      ...Page
    }
  }
`

export default AboutPage
