import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/section_templates"

const _AboutHero = ({ hat, title, subtitle, image, mobileImage }) => {
  return (
    <AboutHero>
      <VerticalSection
        first
        hat={hat}
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        mobileImageOrder="2"
        mobileImage={mobileImage}
        mobileFull
        image={{
          fluid: image.fluid,
          alt: "fluid image test",
          fadeIn: false,
          loading: "eager",
        }}
      />
    </AboutHero>
  )
}

const AboutHero = styled.div`
  --section-padding-top: var(--sp-48);
  --section-mobile-padding-top: 0;
`

export default _AboutHero
