import React from "react"
import styled from "styled-components"

import { Grid } from "components/common"
import { ValueProp } from "components/valueProps"
import { VerticalSection } from "components/section_templates"

const _AboutValues = ({ hat, title, subtitle, valueProps }) => {
  return (
    <AboutValues>
      <VerticalSection
        hat={hat}
        title={title}
        subtitle={subtitle}
        align="center"
        grid={() => (
          <Grid
            toEdge
            component={(props) => (
              <ValueProp
                orientation="horizontal"
                className="aboutValues_vp"
                {...props}
              />
            )}
            list={valueProps}
          />
        )}
      />
    </AboutValues>
  )
}

const AboutValues = styled.div`
  --tg-max-width: 800px;

  .aboutValues_vp {
    .icon {
      box-shadow: var(--icon-shadow);
      border-radius: 50%;
    }
    .icon {
      min-width: 66px;
      img {
        width: 100%;
      }
    }
  }
`

export default _AboutValues
