import React, { useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { TextGroup, Section, Image } from "components/common"
import { device } from "utils"

/**
 * @description Vertical Section
 * @typedef {{
 *  hat: {}
 *  hatAs: String
 *  title: {}
 *  titleAs: String
 *  subtitle: {}
 *  subtitleAs: String
 *  full: Boolean
 *  mobileFull: Boolean
 *  align: "left" | "center" | "right"
 *  image: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  backgroundImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  mobileImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  mobileBackgroundImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  ctas: React.FunctionComponent
 *  grid: React.FunctionComponent
 
 *  custom: React.FunctionComponent
 *  tgOrder: "1" | "2" | "3" | "4" | "5"
 *  ctaOrder: "1" | "2" | "3" | "4" | "5"
 *  imageOrder: "1" | "2" | "3" | "4" | "5"
 *  gridOrder: "1" | "2" | "3" | "4" | "5"
 *  customOrder: "1" | "2" | "3" | "4" | "5"
 * mobileTgOrder: "1" | "2" | "3" | "4" | "5"
 * mobileCtaOrder: "1" | "2" | "3" | "4" | "5"
 * mobileImageOrder: "1" | "2" | "3" | "4" | "5"
 * mobileGridOrder: "1" | "2" | "3" | "4" | "5"
 * mobileCustomOrder: "1" | "2" | "3" | "4" | "5"
 * }}
 * Props
 * @type React.FunctionComponent<Props>
 */

const VerticalSection = ({
  first,
  hat,
  hatAs,
  title,
  titleAs,
  subtitle,
  subtitleAs,
  full,
  mobileFull,
  align,
  image,
  backgroundImage,
  mobileImage,
  mobileBackgroundImage,
  ctas,
  grid,

  custom,
  tgOrder,
  ctaOrder,
  imageOrder,
  gridOrder,
  customOrder,
  mobileTgOrder,
  mobileCtaOrder,
  mobileImageOrder,
  mobileGridOrder,
  mobileCustomOrder,
  ...other
}) => {
  const renderCtas = useCallback(() => ctas(), [ctas])
  const renderCustom = useCallback(() => custom(), [custom])
  const renderGrid = useCallback(() => grid(), [grid])

  return (
    <StyledVerticalSection align={align} {...other}>
      <Section
        mobileImage={mobileBackgroundImage}
        image={backgroundImage}
        full={full}
        first={first}
      >
        <TGWrap
          className="tgWrap"
          align={align}
          tgOrder={tgOrder}
          mobileTgOrder={mobileTgOrder}
        >
          <TextGroup
            hat={hat}
            hatAs={hatAs}
            title={title}
            titleAs={titleAs}
            subtitle={subtitle}
            subtitleAs={subtitleAs}
            align={align}
          />
        </TGWrap>

        {ctas && (
          <CTAWrap
            className="ctaWrap"
            align={align}
            ctaOrder={ctaOrder}
            mobileCtaOrder={mobileCtaOrder}
          >
            {renderCtas()}
          </CTAWrap>
        )}

        {image && (
          <ImageWrap
            align={align}
            height={image.height}
            imageOrder={imageOrder}
            mobileImageOrder={mobileImageOrder}
            width={image.width}
            className={
              full
                ? "full imageWrap"
                : mobileFull
                ? "mobileFull imageWrap"
                : "imageWrap"
            }
          >
            <Image
              image={image}
              mobileImage={mobileImage}
              fadeIn={image.fadeIn}
              loading={image.loading}
            />
          </ImageWrap>
        )}

        {grid && (
          <GridWrap
            className="gridWrap"
            gridOrder={gridOrder}
            mobileGridOrder={mobileGridOrder}
          >
            {renderGrid()}
          </GridWrap>
        )}

        {custom && (
          <CustomWrap
            className="customWrap"
            customOrder={customOrder}
            mobileCustomOrder={mobileCustomOrder}
          >
            {renderCustom()}
          </CustomWrap>
        )}
      </Section>
    </StyledVerticalSection>
  )
}

const ALIGN_LIB = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
}

const StyledVerticalSection = styled.div``

const TGWrap = styled.div`
  display: flex;
  justify-content: ${({ align }) => ALIGN_LIB[align]};
  order: ${({ mobileTgOrder }) => mobileTgOrder};

  margin-bottom: ${({ mobileTgOrder }) =>
    mobileTgOrder === "1" ? "var(--sp-32)" : 0};

  @media ${device.laptop} {
    margin-bottom: 0;
    order: ${({ tgOrder }) => tgOrder};
  }
`

const ImageWrap = styled.div`
  width: 100%;
  max-width: ${({ width }) => width};
  height: ${({ height }) => height};
  align-self: ${({ align }) => ALIGN_LIB[align]};
  order: ${({ mobileImageOrder }) => mobileImageOrder};
  margin: 0 auto var(--sp-32);
  margin: ${({ mobileImageOrder }) =>
    mobileImageOrder === "1" ? "0 auto var(--sp-32)" : "var(--sp-32) auto 0"};

  @media ${device.laptop} {
    order: ${({ imageOrder }) => imageOrder};
    margin: ${({ imageOrder }) =>
      imageOrder === "1" ? "0 auto var(--sp-64)" : "var(--sp-64) auto 0"};
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  &.mobileFull {
    position: relative;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    right: 50%;
    width: 100vw;
    align-self: auto;
    @media ${device.laptop} {
      width: 100%;
      left: initial;
      right: initial;
      max-width: ${({ width }) => width};
      height: ${({ height }) => height};
      order: ${({ imageOrder }) => imageOrder};
      margin: ${({ imageOrder }) =>
        imageOrder === "1" ? "0 0 var(--sp-64)" : "var(--sp-64) 0 0"};
      align-self: ${({ align }) => ALIGN_LIB[align]};
    }
  }

  &.full {
    @media ${device.laptop} {
      position: relative;
      left: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      max-width: 100vw;
      right: 50%;
      width: 100vw;
      align-self: auto;
    }
  }
`

const CTAWrap = styled.div`
  margin-top: var(--sp-32);
  align-self: ${({ align }) => ALIGN_LIB[align]};
  order: ${({ mobileCtaOrder }) => mobileCtaOrder};
  @media ${device.laptop} {
    order: ${({ ctaOrder }) => ctaOrder};
  }
`

const GridWrap = styled.div`
  margin-top: var(--sp-32);

  order: ${({ mobileGridOrder }) => mobileGridOrder};
  @media ${device.laptop} {
    order: ${({ gridOrder }) => gridOrder};
    margin-top: var(--sp-64);
  }
`

const CustomWrap = styled.div`
  margin-top: var(--sp-32);
  order: ${({ mobileCustomOrder }) => mobileCustomOrder};
  @media ${device.laptop} {
    order: ${({ customOrder }) => customOrder};
  }
`

VerticalSection.defaultProps = {
  first: false,
  hat: "",
  hatAs: "h5",
  title: "",
  titleAs: "h2",
  subtitle: "",
  subtitleAs: "p",
  full: false,
  mobileFull: false,
  align: "center",
  image: null,

  cta: null,
  grid: null,
  tgOrder: "1",
  ctaOrder: "2",
  imageOrder: "3",
  gridOrder: "4",
  customOrder: "4",

  mobileImageOrder: "1",
  mobileTgOrder: "2",
  mobileCtaOrder: "3",
  mobileGridOrder: "4",
  mobileCustomOrder: "4",
}

// VerticalSection.propTypes = {
//   hat: PropTypes.oneOfType([
//     PropTypes.shape({
//       json: PropTypes.object.isRequired,
//     }),
//     PropTypes.string,
//   ]),
//   hatAs: PropTypes.string,
//   title: PropTypes.oneOfType([
//     PropTypes.shape({
//       json: PropTypes.object.isRequired,
//     }),
//     PropTypes.string,
//   ]),
//   titleAs: PropTypes.string,
//   subtitle: PropTypes.oneOfType([
//     PropTypes.shape({
//       json: PropTypes.object.isRequired,
//     }),
//     PropTypes.string,
//   ]),
//   subtitleAs: PropTypes.string,
//   full: PropTypes.bool,
//   mobileFull: PropTypes.bool,
//   align: PropTypes.oneOf(["left", "right", "center"]),
//   image: PropTypes.shape({
//     height: PropTypes.string,
//     width: PropTypes.string,
//     fixed: PropTypes.shape({
//       height: PropTypes.number.isRequired,
//       width: PropTypes.number.isRequired,
//     }),
//     fluid: PropTypes.shape({
//       sizes: PropTypes.string.isRequired,
//     }),
//   }),
//   backgroundImage: PropTypes.shape({
//     height: PropTypes.string,
//     width: PropTypes.string,
//     fixed: PropTypes.shape({
//       height: PropTypes.number.isRequired,
//       width: PropTypes.number.isRequired,
//     }),
//     fluid: PropTypes.shape({
//       sizes: PropTypes.string.isRequired,
//     }),
//   }),
//   mobileImage: PropTypes.shape({
//     height: PropTypes.string,
//     width: PropTypes.string,
//     fixed: PropTypes.shape({
//       height: PropTypes.number.isRequired,
//       width: PropTypes.number.isRequired,
//     }),
//     fluid: PropTypes.shape({
//       sizes: PropTypes.string.isRequired,
//     }),
//   }),
//   mobileBackgroundImage: PropTypes.shape({
//     height: PropTypes.string,
//     width: PropTypes.string,
//     fixed: PropTypes.shape({
//       height: PropTypes.number.isRequired,
//       width: PropTypes.number.isRequired,
//     }),
//     fluid: PropTypes.shape({
//       sizes: PropTypes.string.isRequired,
//     }),
//   }),
//   ctas: PropTypes.func,
//   grid: PropTypes.func,

//   custom: PropTypes.func,

//   tgOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   ctaOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   imageOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   gridOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   customOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,

//   mobileTgOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   mobileCtaOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   mobileImageOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   mobileGridOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
//   mobileCustomOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
// }

export default VerticalSection
